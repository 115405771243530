body {
    --navigation-color: hsl(var(--color-blue-darken-4));
    --navigation-color-active: hsl(var(--color-blue-darken-3));
    width: 100%;
    background-color: hsl(var(--color-white));
    color: hsl(var(--color-black));
    font-family: 'Titillium Web', Ubuntu, Tahoma, Nimbus Sans L, Free Sans, sans-serif;
    font-size: clamp(0.8rem, 0.76rem + 0.21vw, 1rem);
    line-height: 1.4;
    font-weight: 400;
    transition: font-size var(--transition-duration) var(--transition-timing);

    &,
    * {
        cursor: crosshair;
    }

    @media (min-width: 900px) {
        overflow-y: hidden;
    }
}

code,
kbd,
samp {
    font-family: 'Roboto Mono', monospace;
}

::selection {
    background: var(--selection-background, hsl(var(--color-blue-darken-4)));
    color: hsl(var(--color-white));
}

.animated-block {
    opacity: 0;
}

:root {
    --zindex-aside: 100;
    --zindex-footer: 105;
    --zindex-header: 110;
    --zindex-modal: 200;
    --zindex-alert: 201;
    --zindex-loader: 210;
    --zindex-curtain: 990;
    --zindex-cursor: 1000;
}
